import { useContext } from 'react'
import { useIntl } from 'react-intl'
import {
  AppContext,
  EDAReportAudiencesColumnHeader,
  EDOOHReportLocationsColumnHeader,
  EReportingMetricNumberType,
  ESPReportProductsColumnHeader,
} from '../../../models'
import { TDatasetWhitelistKey, TGlobalMetricData } from '../models'
import {
  EDAReportCampaignsColumnHeader,
  EDAReportKeywordsColumnHeader,
  EDAReportPositionsColumnHeader,
  EDAReportProductsColumnHeader,
  EDOOHReportCampaignsColumnHeader,
  EGenericReportCampaignsColumnHeader,
  ESPReportCampaignsColumnHeader,
  ESPReportKeywordsColumnHeader,
} from '../../../models/core'
import { PORTAL_FF_REPORTING_METRICS_OMIT } from '../../../utils/constants'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'

export const useGetDatasetKeyChartWhitelist = ({
  key,
}: {
  key: TDatasetWhitelistKey
}) => {
  const intl = useIntl()
  const featureFlags = useFeatureFlags()

  const [appContext] = useContext(AppContext)

  const orgType = appContext.userOrg!.type

  let whiteListMetrics: Record<
    TDatasetWhitelistKey,
    Array<TGlobalMetricData>
  > = {
    'LOCATION-DOOH': [],
    'AUDIENCE-DA': [],
    'PRODUCT-SP': [],
    'PRODUCT-SP-ADGROUP': [],
    'PRODUCT-DA': [],
    'PRODUCT-DA-ADGROUP': [],
    'POSITION-DA': [],
    'POSITION-DA-ADGROUP': [],
    'CAMPAIGN-GENERIC': [],
    'CAMPAIGN-SP': [],
    'CAMPAIGN-DA': [],
    'CAMPAIGN-DOOH': [],
    'KEYWORD-SP-ADGROUP': [],
    'KEYWORD-DA-ADGROUP': [],
  }

  const whitelist: Record<TDatasetWhitelistKey, Array<TGlobalMetricData>> = {
    'LOCATION-DOOH': [
      {
        metricName: EDOOHReportLocationsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDOOHReportLocationsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Cpm
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: EDOOHReportLocationsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDOOHReportLocationsColumnHeader.Playouts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportLocationsColumnHeader.Playouts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'AUDIENCE-DA': [
      {
        metricName: EDAReportAudiencesColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportAudiencesColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportAudiencesColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportAudiencesColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.UniqueReach
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportAudiencesColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Frequency
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportAudiencesColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportAudiencesColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportAudiencesColumnHeader.Cpm
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
    ],
    'PRODUCT-SP': [
      {
        metricName: ESPReportProductsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportProductsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportProductsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.Cpc,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Cpc
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.AvgRank,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.AvgRank
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
      },
      {
        metricName: ESPReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'PRODUCT-SP-ADGROUP': [
      {
        metricName: ESPReportProductsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportProductsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportProductsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.Cpc,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Cpc
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.AvgRank,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.AvgRank
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
      },
      {
        metricName: ESPReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'PRODUCT-DA': [
      {
        metricName: EDAReportProductsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportProductsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.UniqueReach
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Frequency
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportProductsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'PRODUCT-DA-ADGROUP': [
      {
        metricName: EDAReportProductsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: ESPReportProductsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportProductsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportProductsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.UniqueReach
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Frequency
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvCarts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvPurchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportProductsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportProductsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.PvRevenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportProductsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportProductsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'POSITION-DA': [
      {
        metricName: EDAReportPositionsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.UniqueReach
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Frequency
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Cpm
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.ShareOfVoice
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.WinRate
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvCarts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvPurchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvRevenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'POSITION-DA-ADGROUP': [
      {
        metricName: EDAReportPositionsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.UniqueReach
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Frequency
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Cpm
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.ShareOfVoice
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.WinRate
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvCarts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvPurchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.PvRevenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportPositionsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportPositionsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'CAMPAIGN-GENERIC': [
      {
        metricName: EGenericReportCampaignsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EGenericReportCampaignsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EGenericReportCampaignsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EGenericReportCampaignsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EGenericReportCampaignsColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.ShareOfVoice
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EGenericReportCampaignsColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.WinRate
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EGenericReportCampaignsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EGenericReportCampaignsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EGenericReportCampaignsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EGenericReportCampaignsColumnHeader.NewToBrandPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.NewToBrandPurchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EGenericReportCampaignsColumnHeader.NewToBrandRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.NewToBrandRevenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EGenericReportCampaignsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EGenericReportCampaignsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'CAMPAIGN-SP': [
      {
        metricName: ESPReportCampaignsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.Cpc,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Cpc
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.AvgRank,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.AvgRank
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.ShareOfVoice
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.WinRate
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.NewToBrandPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.NewToBrandPurchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.NewToBrandRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.NewToBrandRevenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportCampaignsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportCampaignsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'CAMPAIGN-DA': [
      {
        metricName: EDAReportCampaignsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.UniqueReach
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Frequency
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Cpm
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.ShareOfVoice
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.WinRate
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvCarts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvPurchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.PvRevenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportCampaignsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportCampaignsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'CAMPAIGN-DOOH': [
      {
        metricName: EDOOHReportCampaignsColumnHeader.Playouts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Playouts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDOOHReportCampaignsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDOOHReportCampaignsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDOOHReportCampaignsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.Cpm
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: EDOOHReportCampaignsColumnHeader.ShareOfVoice,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.ShareOfVoice
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDOOHReportCampaignsColumnHeader.WinRate,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDOOHReportCampaignsColumnHeader.WinRate
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
    ],
    'KEYWORD-SP-ADGROUP': [
      {
        metricName: ESPReportKeywordsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportKeywordsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportKeywordsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: ESPReportKeywordsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: ESPReportKeywordsColumnHeader.Cpc,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Cpc
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: ESPReportKeywordsColumnHeader.AvgRank,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.AvgRank
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
      },
      {
        metricName: ESPReportKeywordsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportKeywordsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportKeywordsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: ESPReportKeywordsColumnHeader.NewToBrandPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.NewToBrandPurchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportKeywordsColumnHeader.NewToBrandRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.NewToBrandRevenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: ESPReportKeywordsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            ESPReportKeywordsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
    'KEYWORD-DA-ADGROUP': [
      {
        metricName: EDAReportKeywordsColumnHeader.Impressions,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Impressions
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.Clicks,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Clicks
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.Ctr,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Ctr
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isPercentage: true,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.UniqueReach,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.UniqueReach
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.Frequency,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Frequency
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.Costs,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Costs
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.Cpm,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Cpm
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Avg,
        isCurrency: true,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.Carts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Carts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.PvCarts,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.PvCarts
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.Purchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Purchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.PvPurchases,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.PvPurchases
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.Revenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Revenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.PvRevenue,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.PvRevenue
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
        isCurrency: true,
      },
      {
        metricName: EDAReportKeywordsColumnHeader.Roas,
        translatedMetricName: intl.formatMessage({
          id: `report.table.header.${orgType.toLowerCase()}.${
            EDAReportKeywordsColumnHeader.Roas
          }`,
        }),
        metricNumberType: EReportingMetricNumberType.Sum,
      },
    ],
  }

  if (
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT] &&
    featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].length
  ) {
    whiteListMetrics[key] = whitelist[key].filter(
      (column) =>
        !featureFlags[PORTAL_FF_REPORTING_METRICS_OMIT].includes(
          column.metricName
        )
    )
  } else {
    whiteListMetrics = whitelist
  }

  return whiteListMetrics[key]
}
