import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { ReportPageTemplateLayout } from '../../components/ReportPageTemplateLayout'
import { ReportingKeywordsChartWrapper } from './ReportingKeywordsChartWrapper'
import { TReportExportType, TReportingFilters } from '../../../../models'
import { AppContext, CampaignContext } from '../../../../models/contexts'
import { selectReportingFilters } from '../../../../store/reportingFilters'
import {
  NOT_AVAILABLE,
  exportFileDateFormat,
} from '../../../../utils/constants'
import { exportTableDataToFile } from '../../helpers'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { ReportingKeywordsTableWrapper } from './ReportingKeywordsTableWrapper'
import {
  IDAReportKeywordsTable,
  ISPReportKeywordsTable,
} from '../../../../models/core'
import {
  convertChannelTypeToParam,
  convertEParamChannelTypeToEChannelTypeGlobal,
} from '../../../../utils/helpers'
import { getKeywordsEDatasetKeyFromChannelTypeGlobal } from '../helpers/get-keywords-e-dataset-key-from-channel-type-global'
import { TDatasetWhitelistKey } from '../../models'

export const ReportingKeywordsTemplate = () => {
  const pageTitle = 'reporting.header.keywords'

  const intl = useIntl()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const { channelType: reportingFilterChannelType } = useSelector(
    selectReportingFilters
  )
  const channelType = convertEParamChannelTypeToEChannelTypeGlobal(
    reportingFilterChannelType ||
      convertChannelTypeToParam(campaign.channelType)
  )

  const [tableData, setTableData] = useState<
    ISPReportKeywordsTable | IDAReportKeywordsTable
  >()

  const onTableDataChange = (
    keywordsTableData: ISPReportKeywordsTable | IDAReportKeywordsTable
  ) => {
    setTableData(keywordsTableData)
  }
  const whitelistData = useGetDatasetKeyTableWhitelist({
    key:
      (tableData?.datasetKey as TDatasetWhitelistKey) ||
      getKeywordsEDatasetKeyFromChannelTypeGlobal(channelType),
  })

  const onExportData = (
    { startDate: start, endDate: end }: TReportingFilters,
    type: TReportExportType
  ) => {
    const orgName =
      appContext.userOrg?.name ?? appContext.userOrg?.type ?? NOT_AVAILABLE
    const translatedPageTitle = intl
      .formatMessage({ id: pageTitle })
      .replace(/[-]+/g, '')
      .replace(/[ ]+/g, '_')
    const sDate = dayjs(start).format(exportFileDateFormat)
    const eDate = dayjs(end).format(exportFileDateFormat)

    const fileName = `${orgName}_${translatedPageTitle}_${sDate}_${eDate}`
    if (tableData) {
      exportTableDataToFile({
        tableDataRows: tableData.rows,
        tableHeaders: whitelistData,
        fileName,
        fileType: type,
      })
    }
  }

  return (
    <>
      <ReportPageTemplateLayout
        title={pageTitle}
        isExportDisabled={Boolean(!tableData?.rows.length)}
        chartWrapper={<ReportingKeywordsChartWrapper />}
        tableWrapper={
          <ReportingKeywordsTableWrapper
            onTableDataChange={onTableDataChange}
          />
        }
        onExportData={onExportData}
      />
    </>
  )
}
