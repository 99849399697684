import {
  EDOOHLocation,
  EDOOHTargetType,
  IDOOHAdGroupTargeting,
} from '../../models'

export const getLocationTypeByTargetType = (
  targeting: IDOOHAdGroupTargeting
): EDOOHLocation | undefined => {
  if (
    [
      EDOOHTargetType.PositionOnly,
      EDOOHTargetType.AudienceAndPosition,
    ].includes(targeting.targetType)
  ) {
    return EDOOHLocation.ALL_STORES
  }

  if (targeting.targetType === EDOOHTargetType.LocationAndPosition) {
    if (
      'storeLocationsList' in targeting.locationsList &&
      targeting.locationsList.storeLocationsList.length
    ) {
      return EDOOHLocation.STORES
    }
    if (
      'cityLocationsList' in targeting.locationsList &&
      targeting.locationsList.cityLocationsList.length
    ) {
      return EDOOHLocation.CITIES
    }
  }
}
